import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about',
  template: `
  <section class="hero is-success">
    <div class="hero-body">
      <div class="container">
        <h1 class="title">
          About Me
        </h1>
      </div>
    </div>
  </section>
  <body>

  <section>
    <img src="/assets/img/Headshot.png" alt="please be patient">
  </section>

  <section class="section">
    <div class="container">
      <h1 class="title has-text-centered"></h1>
      <h2 class="subtitle">
      Highly motivated aspiring software developer with experience in product development and automation. Demonstrates success in collaborative thinking, independent learning, and versatility. Recognized for leadership, organization, methodical problem-solving.
      </h2>
    </div>
  </section>
</body>
  `,
  styles: [
  ]
})
export class AboutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
